import pathToRegexp from 'path-to-regexp';
import config, {
  isDevelopment,
  isEvaluation,
  isStaging,
  isProduction,
} from 'config';

// ⚠️ PLEASE DO NOT USE THESE SETTINGS LINKS DIRECTLY ️️️⚠️
// Use `components/SettingsLink` instead, which directs the user to the desired page for the
// current account.
export const SETTINGS_URL = '/account';
export const SETTINGS_PAGE_URL = {
  ACCOUNT: `${SETTINGS_URL}?k=account`,
  APPS_INTEGRATIONS: `${SETTINGS_URL}?k=apps-integrations`,
  BRANDING: `${SETTINGS_URL}?k=branding`,
  NOTIFICATION: `${SETTINGS_URL}?k=notification`,
  PROFILE: `${SETTINGS_URL}?k=profile`,
  TEAM_DIRECTORY: `${SETTINGS_URL}?k=directory`,
  TEAMS: `${SETTINGS_URL}?k=teams`,
  USERS: `${SETTINGS_URL}?k=users`,
};
export const V2_SETTINGS_PAGE_URL = {
  ACCOUNT_SETTINGS: `${SETTINGS_URL}/account-settings`,
  BILLING: `${SETTINGS_URL}/billing`,
  BRANDING: `${SETTINGS_URL}/branding`,
  GROUPS: `${SETTINGS_URL}/groups`,
};
export const BETTER_ACCOUNT_SETTINGS_URL = config.betterAccountSettingsUrl;
export const BETTER_ACCOUNT_SETTINGS_PAGE_URL = {
  MANAGE_NOTIFICATIONS: `${BETTER_ACCOUNT_SETTINGS_URL}/notifications`,
  MANAGE_PROFILE: `${BETTER_ACCOUNT_SETTINGS_URL}/profile`,
  MANAGE_PROJECTS: `${BETTER_ACCOUNT_SETTINGS_URL}/projects`,
  MANAGE_USERS: `${BETTER_ACCOUNT_SETTINGS_URL}/users`,
  MANAGE_TEAMS: `${BETTER_ACCOUNT_SETTINGS_URL}/teams`,
};
// ⚠️ PLEASE DO NOT USE THESE SETTINGS LINKS DIRECTLY ️️️⚠️

export const PROJECT_TABS = {
  C2C_CONNECTIONS: 'c2c_connections',
  FILES: 'files',
  LINKS: 'sharing',
  PRESENTATIONS: 'presentations',
};

export const ACCOUNT_URL = '/accounts/:accountId';
export const ACCOUNT_SEARCH_URL = `${ACCOUNT_URL}/search`;
export const COMPARE_URL = '/compare/:versionId?';
export const CONFIRM_EMAIL_URL = '/confirm-email';
export const DO_NOT_SHARE_PRIVACY_INFO_URL = '/dnss-optout';
export const INBOX_URL = `${ACCOUNT_URL}/inbox`;
export const FORGOT_URL = '/forgot';
export const LOGOUT_URL = '/logout';
export const MARKETING_URL = 'https://frame.io';
export const NOT_FOUND_URL = '/404';
export const OAUTH_CALLBACK_URL = '/oauth-callback/:provider';
export const PLAYER = '/player/:assetId';
export const PRESENTATION_URL = '/presentations/:vanityId';
export const PRODUCT_UPDATES_URL = 'https://updates.frame.io/';
export const PROJECT_C2C_CONNECTIONS_URL =
  '/projects/:projectId/c2c-connections';
export const PROJECT_URL = '/projects/:projectId/:folderId?';
export const PROJECT_LINKS_URL = '/projects/:projectId/review-links';
export const PROJECT_PRESENTATIONS_URL = '/projects/:projectId/presentations';
export const RESET_URL = '/reset';

export const REVIEW_LINK_COMPARE_URL =
  '/reviews/:reviewLinkId/:versionId/compare';
export const REVIEW_LINK_URL = '/reviews/:reviewLinkId/:assetId?';
export const ROOT_URL = '/';
export const SHARED_PROJECT_URL = '/shared/projects/:id';
export const SHARED_PROJECT_FOR_INVITE_URL = '/shared/projects/invites/:token';
export const TEAM_URL = '/accounts/:accountId/:teamId';
export const TERMS_URL = 'https://www.frame.io/terms';
export const PRIVACY_URL = 'https://www.frame.io/privacy';
export const WELCOME_URL = '/welcome';
export const ADOBE_LOGIN = '/adobe-login';
export const MIGRATE_TO_V4_URL = '/get-v4';

// Support URLs
export const REVIEW_LINKS_FOR_CLIENTS_SUPPORT_URL =
  'https://support.frame.io/getting-started/sharing-with-your-team-or-clients/review-links-explained-for-clients';
export const PERSONAL_POLICY_URL =
  'https://frame.io/privacy#my-personal-information';
export const SHARE_SUPPORT_URL =
  'https://support.frame.io/en/articles/5042489-getting-started-how-do-i-share-with-others';
export const SHARING_REVIEW_LINKS_SUPPORT_URL =
  'https://support.frame.io/en/articles/414306-sharing-your-files-and-folders-as-a-review-link';
export const PROJECT_SHARING_SUPPORT_URL =
  'https://support.frame.io/en/articles/7781-project-sharing-invite-multiple-users-to-your-project';
export const ORANGE_BANNER_SUPPORT_URL =
  'https://support.frame.io/en/articles/2673548-orange-notification-banner-across-my-team';
export const ADBLOCK_SUPPORT_URL =
  'https://support.frame.io/en/articles/22098-ad-blocker-prevents-functional-workflow';
export const ARCHIVED_PROJECTS_SUPPORT_URL =
  'https://support.frame.io/en/articles/2816994-archived-projects';
export const SLACK_INTEGRATION_SUPPORT_URL =
  'https://support.frame.io/en/articles/7059-integration-with-slack-messaging-app';
export const ENTERPRISE_ROLES_SUPPORT_URL =
  'https://support.frame.io/en/articles/8517-enterprise-account-management-roles';
export const ENTERPRISE_LIMITS_SUPPORT_URL =
  'https://support.frame.io/en/articles/844555-enterprise-team-member-and-storage-allocation-limits';
export const TEAM_MEMBER_VS_COLLABORATOR_SUPPORT_URL =
  'https://support.frame.io/en/articles/6067-difference-between-team-members-vs-collaborators';
export const PROJECT_ACCESS_SUPPORT_URL =
  'https://support.frame.io/en/articles/3074868-requesting-and-granting-project-access';
export const STATUS_URL = 'https://status.frame.io/';
export const SUPPORT_URL = 'https://support.frame.io/';
export const SALES_TAX_INFO_SUPPORT_URL =
  'https://support.frame.io/en/articles/5952361-updating-your-billing-address-faq';

/*
  ⚠️ NOTE: The below pathname helpers are not meant to be used loosely,
  and should only be considered as a _very last_ resort. Fundamentally,
  we should be relying on router-specific data already provided by
  `match` in `react-router-dom`, instead of global location data,
  which is when these helpers may come in handy.
*/
export function isPathNameAccountsPage(pathname) {
  return /^\/accounts\//.test(pathname);
}

export function isPathNamePlayerPage(pathname) {
  return /^\/player|compare\//.test(pathname);
}

export function isPathNameReviewPage(pathname) {
  return /^\/reviews\//.test(pathname);
}

export function isPathNamePresentationPage(pathname) {
  return /^\/presentations\//.test(pathname);
}

export function isPathNameProjectsPage(pathname) {
  return /^\/projects\//.test(pathname);
}

export function isPathNameSearchPage(pathname) {
  return /\/search/.test(pathname);
}

// Cache functions compiled by pathToRegexp
const toPathCache = {};

/**
 * Generate a path with params.
 * @param {string} path - A path that the path-to-regexp module can parse.
 * @param {object} params - Object with keys that corresponds to named params in
 *   the path.
 * @returns {string} - A path with the params filled in.
 */
export function getPath(routerPath, params) {
  const toPath = toPathCache[routerPath] || pathToRegexp.compile(routerPath);
  return toPath(params);
}

/**
 * Generates the absolute url for a given path.
 * @param {string} pathname - URL pathname ex: /presentations/123.
 */
export function getAbsoluteUrl(pathname, params) {
  return `${config.clientHost}${getPath(pathname, params)}`;
}

/**
 * Generates the path to a project or a folder within the project.
 * @param {?string} projectId - Project id.
 * @param {?string} folderId - Folder id.
 * @returns {string} - Url.
 */
export function getProjectUrl(projectId, folderId) {
  if (!projectId) {
    return ROOT_URL;
  }
  return getPath(PROJECT_URL, {
    projectId,
    folderId,
  });
}

/**
 * Returns a map of matched params given a path and pathname
 * @param {string} path - Path to match, e.g. `/:foo/:bar`.
 * @param {string} pathname - URL pathname, e.g. `/boo/baz`.
 * @returns {Object} - Matched path param key-value pair(s), e.g.
 * {
 *   foo: 'boo',
 *   bar: 'baz',
 * }
 */
export function getPathnameParams(path, pathname) {
  const keys = [];
  const regexp = pathToRegexp(path, keys);
  const matches = regexp.exec(pathname) || [];

  if (keys.length === 0 || matches.length === 0) return undefined;

  // Remove first element of matches, which is the the full string of characters matched
  matches.shift();

  const params = {};
  matches.forEach((match, i) => {
    params[keys[i].name] = match;
  });

  return params;
}

/**
 * Generates the path to a folder within the project.
 * Is the same function as getProjectUrl() with a different name? Yes.
 * @param {?string} projectId - Project id.
 * @param {?string} folderId - Folder id.
 * @returns {string} - Url.
 */
export function getFolderUrl(projectId, folderId) {
  if (!projectId) {
    return ROOT_URL;
  }
  return getPath(PROJECT_URL, {
    projectId,
    folderId,
  });
}

/**
 * Generates the path to a team url.
 * @param {?string} accountId - Account id.
 * @param {?string} teamId - Team id.
 * @returns {string} - Url.
 */
export function getTeamUrl(accountId, teamId) {
  return getPath(TEAM_URL, {
    accountId,
    teamId,
  });
}

export function getOAuthCallbackUrl(provider) {
  return getAbsoluteUrl(OAUTH_CALLBACK_URL, { provider });
}

export function getPlayerUrl(assetId, versionStackFileId) {
  const playerPath = getPath(PLAYER, { assetId });
  return versionStackFileId
    ? `${playerPath}?version=${versionStackFileId}`
    : playerPath;
}

/**
 * Generate a public URL.
 * NOTE: In the production, staging, evaluation, and `app.dev.frame.io` domains, this helper
 * returns the provided short URL if it exists.
 *
 * This helper _should not_ be used for changing routes.
 */
function getPublicUrl(shortUrl, path) {
  // The short url service only redirects to app.dev.frame.io,
  // app.staging.frame.io, app.eval.frame.io, and app.frame.io. For other environments, just use
  // the regular url (mainly for the sake of dev ex in local and feature branch
  // envs).
  const isJustAppDotDev =
    isDevelopment && location.hostname === 'app.dev.frame.io';

  return (isProduction || isStaging || isEvaluation || isJustAppDotDev) &&
    shortUrl
    ? shortUrl
    : `${location.origin}${path}`;
}

export const getPublicReviewLinkUrl = (reviewLinkId, shortUrl) =>
  getPublicUrl(shortUrl, getPath(REVIEW_LINK_URL, { reviewLinkId }));

export const getPublicPresentationUrl = (vanityId, shortUrl) =>
  getPublicUrl(shortUrl, getPath(PRESENTATION_URL, { vanityId }));

export const getShareLinkForProject = (projectId, shortUrl) =>
  getPublicUrl(shortUrl, getPath(SHARED_PROJECT_URL, { id: projectId }));
