import React from 'react';
import styled, { keyframes } from 'styled-components';
import Flex from 'styled-flex-component';
import CancelSVG from '@frameio/components/src/svgs/icons/16/cancel.svg';
import Button from '@frameio/components/src/styled-components/Button';
import { MEDIUM_UP, SMALL } from 'utils/mediaQueries';
import { MenuSeparator } from '@frameio/vapor';
import { PERSONAL_POLICY_URL, PRIVACY_URL, TERMS_URL } from 'URLs';
import { IconCustomFieldLink } from '@frameio/vapor-icons';
import { useDispatch } from 'react-redux';

const DialogContainer = styled.div`
  padding: 20px;
  max-width: calc(100vw - 2 * 12px);
`;

const DialogHeader = styled.h2``;

const StyledFlexContainer = styled(Flex)`
  color: ${(p) => p.theme.color.black};
  width: ${(p) => p.theme.spacing.units(39)};
  height: 100%;

  @media ${MEDIUM_UP} {
    max-width: ${(p) => p.theme.spacing.units(70)};
    width: 100%;
    height: 100%;
  }
`;

const StyledCloseButton = styled(Button)`
  height: ${(p) => p.theme.spacing.units(4)};
  padding: ${(p) => p.theme.spacing.units(1)};
  position: absolute;
  right: ${(p) => p.theme.spacing.units(2)};
  top: ${(p) => p.theme.spacing.units(2)};
  width: ${(p) => p.theme.spacing.units(4)};
`;

const LinkButton = styled(Button)`
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  color: #666a72;
  padding: 0 12px;
`;

const fadeIn = () => keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Animation = styled(Flex)`
  /* Will Change is necessary otherwise the section will have a shift/pop on end of reveal */
  will-change: opacity;
  /* add an animation on reveal. as it shows on a delay. */
  animation: ${fadeIn()} 750ms ${(p) => p.theme.easing.primary};
  animation-delay: 0ms;
  animation-fill-mode: backwards;
`;

const Section = styled(Animation)`
  min-height: ${(p) => (p.minHeight === undefined ? 'auto' : p.minHeight)};

  &:first-of-type {
    padding-bottom: 0;
  }

  &:last-of-type {
    /* the padding is set deeper in the component
    so that the StyledShadowContainer is flush to the modal edge for the content. */
    padding: ${(p) => p.theme.spacing.small} 0 0;
  }

  .Settings__UserSearch {
    box-shadow: none;
  }
`;

const StyledFlexForLink = styled(Animation)`
  padding: ${(p) => p.theme.spacing.small} 0;
`;

const StyledH3 = styled.h3`
  align-content: center;
`;

const StyledP = styled.p`
  color: ${(p) => p.theme.color.black};

  ${(p) => p.theme.fontStyle.bodyM};

  @media ${SMALL} {
    ${(p) => p.theme.fontStyle.bodyS};
  }
`;

const TEXT = {
  header: 'Legal Notices',
  thirdParty: {
    label: 'Third Party',
    description: (
      <>
        Third Party notices, terms and conditions pertaining to third party
        software can be found at{' '}
        <a
          href="https://www.adobe.com/products/eula/third_party.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.adobe.com/products/eula/third_party.html
        </a>{' '}
        and are incorporated by reference.
      </>
    ),
  },
  copyright: {
    label: 'Copyright',
    description: (
      <>
        © 2024 Adobe. All rights reserved.
        <br /> Adobe, the Adobe logo and{' '}
        <a href="http://frame.io/" rel="noopener noreferrer" target="_blank">
          Frame.io
        </a>{' '}
        are either registered trademarks or trademarks of Adobe in the United
        States and/or other countries. All other trademarks are the property of
        their respective owners.
      </>
    ),
  },
  terms: 'Terms of Service',
  privacy: 'Privacy Policy',
  personal: 'Do Not Sell or Share My Personal Information',
};

function LegalDialog({ closeModal }) {
  const dispatch = useDispatch();
  return (
    <DialogContainer>
      <StyledFlexContainer column>
        <DialogHeader>{TEXT.header}</DialogHeader>
        <StyledCloseButton
          icon
          text
          type="button"
          onClick={() => dispatch(closeModal())}
        >
          <CancelSVG />
        </StyledCloseButton>
        <MenuSeparator />
        <Section row full minHeight={0} as="section">
          <Flex column alignStart justifyStart>
            <StyledP>{TEXT.copyright.description}</StyledP>
          </Flex>
        </Section>
        <Section row full minHeight={0} as="section">
          <Flex column alignStart justifyStart>
            <StyledP>{TEXT.thirdParty.description}</StyledP>
          </Flex>
        </Section>
        <MenuSeparator />
        <StyledFlexForLink row full justifyBetween>
          <StyledH3>{TEXT.terms}</StyledH3>
          <LinkButton text href={PRIVACY_URL} target="_blank" as="a">
            View <IconCustomFieldLink />
          </LinkButton>
        </StyledFlexForLink>
        <MenuSeparator />
        <StyledFlexForLink row full justifyBetween>
          <StyledH3>{TEXT.privacy}</StyledH3>
          <LinkButton text href={TERMS_URL} target="_blank" as="a">
            View <IconCustomFieldLink />
          </LinkButton>
        </StyledFlexForLink>
        <MenuSeparator />
        <StyledFlexForLink row full justifyBetween>
          <StyledH3>{TEXT.personal}</StyledH3>
          <LinkButton text href={PERSONAL_POLICY_URL} target="_blank" as="a">
            View <IconCustomFieldLink />
          </LinkButton>
        </StyledFlexForLink>
      </StyledFlexContainer>
    </DialogContainer>
  );
}

export default React.memo(LegalDialog);
