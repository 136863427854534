import { connect } from 'react-redux';
import {
  currentAccountEntitySelector,
  currentAccountSettingsPermissionsSelector,
  hasAccountWithOwnerRoleSelector,
  accountMigrationEligibilitySelector,
} from 'selectors/accounts';
import { currentUserEntitySelector } from 'selectors/users';
import { startSetupAccount } from 'components/AccountCTA/SetupAccountCTA/actions';
import { openModal } from 'components/Modal/actions';
import { openLegalModal } from 'components/LegalDialog/actions';
import TopBarNav from './TopBarNav';

export function mapStateToProps(state) {
  const { display_name: accountDisplayName, id: accountId } =
    currentAccountEntitySelector(state) || {};
  const { email, image_64: image, name, user_default_color: defaultColor } =
    currentUserEntitySelector(state) || {};

  const {
    isAccountEligibleForMigration: canOpenMigrationModal,
  } = accountMigrationEligibilitySelector(state);

  const accountSettingsPermissions = currentAccountSettingsPermissionsSelector(
    state
  );

  return {
    accountDisplayName,
    accountId,
    accountSettingsPermissions,
    canOpenMigrationModal,
    defaultColor,
    email,
    image,
    isFetching: state.dashboardSidebar.isFetching,
    name,
    hasOwnAccount: hasAccountWithOwnerRoleSelector(state),
  };
}

const mapDispatchToProps = {
  openModal,
  startSetupAccount,
  openLegalModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBarNav);

export const testExports = {
  mapStateToProps,
  mapDispatchToProps,
};
